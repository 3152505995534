import React, { useState, useEffect } from 'react'
import ls from 'local-storage'
import LazyLoad from 'react-lazyload'
import { RenderShortcode } from '../../RenderShortcode/RenderShortcode'
import './PopupWindow.scss'

export const PopupWindow = ({ popupContent }) => {
  const [localStorage, setLocalStorage] = useState(() => {
    return ls.get('popupNotice') || false
  });
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setLocalStorage(ls.get('popupNotice'));

    // if there isn't a value in the local storage, show the popup after 15 seconds.
    console.log(localStorage);
    if(!localStorage) {
      setTimeout(() => {
          setShowPopup(true)
      }, 15000)
    }
  },[])

  const closeNotice = () => {
    const updatedDate = new Date();
    updatedDate.setHours(updatedDate.getHours() + 24);
    ls.set('popupNotice', updatedDate);
    setShowPopup(false);
  }

  return (
    <LazyLoad>
      <div className="popup-window">
        {showPopup && (
          <div className="background">
            <div className="container">
              <button
                type="button"
                className="close"
                onClick={() => closeNotice()}
              >
                <span className="lines" />
              </button>
              <RenderShortcode content={popupContent} />
            </div>
          </div>
        )}
      </div>
    </LazyLoad>
  )
}

import React, { Component } from 'react'
import './Header.scss'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel'
import { Breadcrumbs } from '../../Breadcrumbs'
import { decodeEntities } from '../../../utils/helpers'

const isClient = typeof window !== 'undefined'

class HeaderSlider extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if (isClient) {
        let childrenDomElements = document.querySelectorAll('.children')
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav()
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { slider, globalTitle, location, breadcrumbs, centre, squareHeader } = this.props
    const settings = {
      container: 'page-carousel',
      nav: true,
      mouseDrag: true,
      items: 1,
      controls: true,
      rewind: true,
      loop: false,
      mode: 'gallery',
      autoplay: globalTitle ? true : false,
    }
    return (
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slide_count) => (
            <div key={slide_count} className="slide">
              {!globalTitle && (
                <div className={`title-block${centre ? ' centre' : ''}`}>
                  <div className="wrap">
                    <div className="inner">
                      {breadcrumbs && <Breadcrumbs location={location} />}
                      {slide_count == 0 ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <h2
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      {slide.action && (
                        <GatsbyLink
                          to={slide.action.url}
                          className="action"
                          decode={true}
                        >
                          {slide.action.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Image src={slide.background} className="background" />
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const Header = props => {
  const { compact, globalTitle, breadcrumbs, header, subHeader, centre, squareHeader } = props
  return (
    <section 
      className={`
        page-header${compact ? ' compact' : ''}
        ${squareHeader ? ' square-header' : ''}
      `}>
      <HeaderSlider {...props} />
      {globalTitle && (
        <div className={`title-block${centre ? ' centre' : ''}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
              />
              {subHeader && (
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

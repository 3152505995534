/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react'
import Typewriter from 'typewriter-effect-fork'
import { decodeEntities } from '../../../utils/helpers'
import './HeaderHome.scss'
import GatsbyLink from '../../GatsbyLink';

export const HeaderHome = props => {
  const {
    centre,
    compact,
    header,
    subHeader,
    ctaheader,
    video,
    customClass,
    enableAutoTypingHeader,
    autoTypeContent,
    autoTypePrefix,
  } = props
  let videoUrlObject = {}
  if (video) {
    videoUrlObject = { ...video[0] }
  }
  const { videoSource, iframeEmbedLink, videoUrl } = videoUrlObject
 


  // Create array from autoTypeContent
  const typeWriteStringArray = []
  if (autoTypeContent) {
    autoTypeContent.map(item => {
      typeWriteStringArray.push(item.heading)
    })
  }
  return (
    <section
      className={`page-header${compact ? ' compact' : ''} ${customClass || ''}`}
    >
      <div className="header-container">
        <div className="video-wrapper">
          <>
            {videoSource && iframeEmbedLink && (
              <div
                className="video"
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(iframeEmbedLink),
                }}
              />
            )}
            {videoSource && videoUrl && (
              <video 
                autoPlay 
                preload=""
                autoPlay
                loop 
                playsInline
                muted
                type="video/mp4" 
                src={videoUrl} 
              />
            )}
          </>
          <div className="header-wrapper">
            {enableAutoTypingHeader ? (
              typeWriteStringArray.length > 0 && (
                <div className="title typewriter-effect">
                  {autoTypePrefix && (
                    <>
                      <span>{autoTypePrefix}</span>
                      <br />
                    </>
                  )}
                  <Typewriter
                    options={{
                      strings: typeWriteStringArray,
                      autoStart: true,
                      delay: 70,
                      loop: true,
                    }}
                  />
                </div>
              )
            ) : (
              <>
                <h1
                  className="title"
                  dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
                />
                {subHeader && (
                  <p
                    className="sub-title"
                    dangerouslySetInnerHTML={{
                      __html: decodeEntities(subHeader),
                    }}
                  />
                )}
              </>
            )}
            {ctaheader && (
              <div className="header-cta">
                <GatsbyLink to={ctaheader.url} aria-label={ctaheader.title} target={ctaheader.target} className="action" decode>{ctaheader.title}</GatsbyLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'

export const PlayButtonIcon = props => {
  const { strokeColor = '#565656' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="#565656"
      stroke="#565656"
      height="250"
      width="250"

    >
      <path
        d="M 16 4 C 9.3844277 4 4 9.3844277 4 16 C 4 22.615572 9.3844277 28 16 28 C 22.615572 28 28 22.615572 28 16 C 28 9.3844277 22.615572 4 16 4 z M 16 6 C 21.534692 6 26 10.465308 26 16 C 26 21.534692 21.534692 26 16 26 C 10.465308 26 6 21.534692 6 16 C 6 10.465308 10.465308 6 16 6 z M 12 9.125 L 12 10.84375 L 12 21.15625 L 12 22.875 L 13.5 22 L 22.5 16.875 L 24 16 L 22.5 15.125 L 13.5 10 L 12 9.125 z M 14 12.5625 L 19.96875 16 L 14 19.4375 L 14 12.5625 z"
        overflow="visible"
        fontFamily="Bitstream Vera Sans"
      />
    </svg>
  )
}

import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

import RenderContent from '../../RenderContent'
import { Image } from '../../Image'

import './StaffProfiles.scss'

const ProfileImage = ({ image }) => {
  if (!image) return null
  const imageSrc = image?.source_url
  const [profileRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })
  const springProp = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate3d(0, 0, 0)' : 'translate3d(-25px, 50px, 0)',
  })
  return (
    <animated.div ref={profileRef} style={springProp} className="image">
      <Image src={imageSrc} />
    </animated.div>
  )
}

const ProfileText = props => {
  const { profileContent } = props
  const { ...profile } = profileContent
  const [profileRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const springProp = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate3d(0, 0, 0)' : 'translate3d(100px, 50px, 0)',
  })

  return (
    <animated.div ref={profileRef} style={springProp} className="content">
      <div className="heading">
        <p className="name">
          {profile.name}
          <br />
          <strong>{profile.position}</strong>
        </p>
      </div>
      <RenderContent content={profile.profile} />
    </animated.div>
  )
}

export const StaffProfiles = props => {
  const { staffProfiles, title, content, sectionId } = props
  return (
    <section className="staff-profiles" id={sectionId || ''}>
      <div className="wrap">
        <div className="inner">
          <div className="intro">
            {title && <h3 className="section-title">{title}</h3>}
            {content && (
              <RenderContent className="intro-text" content={content} />
            )}
          </div>
          <div className="profiles">
            {staffProfiles &&
              staffProfiles.map((profile, index) => (
                <div className="profile" key={index}>
                  <ProfileImage image={profile.image} />
                  <ProfileText profileContent={profile} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import './NumbersBlock.scss'

export const NumbersBlock = props => {
  const { title, numbers } = props

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const COUNT_UP_START = 1

  return !props ? (
    <p>NumbersBlock component</p>
  ) : (
    <section className="numbers-block">
      <div className="numbers-block--wrapper">
        <div className="numbers-block--inner">
          <div className="numbers-block--title">{title}</div>
          <div className="numbers-block--numbers">
            {numbers &&
              numbers.map((num, index) => (
                <div className="numbers-block--number-item" key={index}>
                  <div className="numbers-block--number-item--inner" ref={ref}>
                    <CountUp
                      className="numbers-block--number-circle"
                      decimals={0}
                      start={inView ? COUNT_UP_START : num.number}
                      end={num.number}
                      suffix={num.symbol}
                      duration={2}
                    >
                      {({ countUpRef }) => (
                        <span
                          className="numbers-block--number-circle"
                          ref={countUpRef}
                        ></span>
                      )}
                    </CountUp>

                    <div className="numbers-block--number-description">
                      {num.description}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

NumbersBlock.propTypes = {
  title: PropTypes.string,
  numbers: PropTypes.array,
  number: PropTypes.number,
  symbol: PropTypes.string,
  description: PropTypes.string,
}

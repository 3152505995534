/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import cx from 'classnames'
import { FaCircleNotch } from 'react-icons/fa'
import ReactPlayer from 'react-player'
import { RenderShortcode } from '../../RenderShortcode/RenderShortcode'
import { SideNavigation } from '../../SideNavigation'
import { Image } from '../../Image'
import { PlayButtonIcon } from '../../../images/icons/PlayButtonIcon'
import './FlexibleBlock.scss'
import '../../../../theme/FlexibleBlock.scss'
import { decodeEntities } from '../../../utils/helpers'

export const FlexibleBlock = props => {
  const getComponent = (item, key, pageContext) => {
    const {
      backgroundStyle,
      actionStyle,
      actionSize,
      content_type,
      contentVideo,
      iframe,
      section_content,
      content_background_image,
      icons,
      iconsIntro,
      iconsCenter,
      video_url,
      placeholder_image,
      video_source,
      text_content,
    } = item

    const [ready, setReady] = useState(false)

    const videoReady = () => {
      setReady(true)
    }

    const isSecondImage = key === 1
    const isMobileImage = key === 'mobile'

    return (
      <div
        className={`col${content_type ? ` ${content_type}` : ''}${
          isMobileImage && content_type === 'image' ? ' mobile' : ''
        }${isSecondImage && content_type === 'image' ? ' desktop' : ''}${
          backgroundStyle && backgroundStyle !== '' && backgroundStyle != 'none'
            ? ` ${backgroundStyle}`
            : ''
        }`}
        key={key}
      >
        {content_type === 'video' && (
          <div
            className="inner-content video-block"
            dangerouslySetInnerHTML={{ __html: contentVideo }}
          />
        )}
        {content_type === 'iframe' && (
          <div className="inner-content iframe-block">
            <iframe src={iframe} frameBorder="0" allowFullScreen />
            <div className="loading">
              <FaCircleNotch />
            </div>
          </div>
        )}
        {content_type === 'video-with-placeholder' && (
          <ReactPlayer
            url={video_url || ''}
            className="inner-content react-player"
            width="100%"
            height="100%"
            light={placeholder_image.source_url}
            playIcon={
              <PlayButtonIcon className="react-player__icon" color="#565656" />
            }
          />
        )}
        {content_type === 'video-with-text' && (
          <div
            className={`
            inner-content
            ${
              text_content
                ? ' text-block extra-padding-section'
                : ' image-block'
            }
            ${actionStyle ? `${actionStyle}__action ` : ''}
            ${actionSize ? `${actionSize}__action ` : ''}
          `}
          >
            <ReactPlayer
              url={video_source || ''}
              className={`inner-content react-player 
              ${text_content ? ' with-extra-padding' : ''}`}
              width="100%"
              height="100%"
              onReady={videoReady}
              playing
              muted
              loop
              volume={1}
            />
            {text_content && (
              <RenderShortcode
                className="text-content"
                content={text_content}
              />
            )}
          </div>
        )}

        {content_type === 'image' && content_background_image && (
          <div className="inner-content image-block">
            <Image src={content_background_image} />
          </div>
        )}

        {content_type === 'text' && (
          <div
            className={`
              inner-content text-block 
              ${actionStyle ? `${actionStyle}__action ` : ''}
              ${actionSize ? `${actionSize}__action ` : ''}
            `}
          >
            {section_content && <RenderShortcode content={section_content} />}
          </div>
        )}

        {content_type === 'menu' && (
          <div className="inner-content text-block menu-block">
            <SideNavigation pageContext={pageContext} />
            {section_content && <RenderShortcode content={section_content} />}
          </div>
        )}
        {content_type === 'icons' && (
          <div
            className={`inner-content icons-block${
              iconsCenter ? ' center' : ''
            }`}
          >
            {iconsIntro && <RenderShortcode content={iconsIntro} />}
            <ul>
              {icons.map((item, index) => (
                <li key={index}>
                  <Image className="inner-image" src={item.icon.source_url} />
                  {item.iconTitle && <p>{decodeEntities(item.iconTitle)}</p>}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
  const {
    columns = [],
    section,
    wrapWidth = wrapWidth || 'wrap',
    backgroundStyle,
    backgroundImage,
    sectionClassName,
    pageContext,
    sectionId,
    bannerValue = false,
    customClass,
  } = props

  const sectionClassPad = section ? ' section' : ''
  const column_number = ['zero', 'one', 'two', 'three', 'four']
  const sectionClass =
    sectionClassName && sectionClassName !== 'none'
      ? cx('flexible', sectionClassName)
      : `flexible block`
  const globalClasses = backgroundStyle
    ? cx(sectionClass, backgroundStyle)
    : sectionClass
  const containsMenu = !!(
    (columns[0] && columns[0].content_type === 'menu') ||
    (columns[1] && columns[1].content_type === 'menu') ||
    (columns[2] && columns[2].content_type === 'menu')
  )

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const springProp = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate3d(0, 0, 0)' : 'translate3d(0px, 15px, 0)',
  })

  return (
    <section
      className={`
        ${globalClasses} ${bannerValue ? 'banner-display' : ''}
        ${customClass || ''}
      `}
      id={sectionId || null}
    >
      <animated.div
        className={`${column_number[columns.length]}-col${
          wrapWidth === 'none' || wrapWidth === '' ? '' : ` ${wrapWidth}`
        }${sectionClassPad}${containsMenu ? ' contains-menu' : ''}`}
        ref={ref}
        style={springProp}
      >
        <div className="inner">
          {// Mobile image column
          columns && columns[1] && columns[1].content_type === 'image'
            ? getComponent(columns[1], 'mobile')
            : null}
          {columns.map((item, index) => getComponent(item, index, pageContext))}
        </div>
      </animated.div>
      {backgroundImage && (
        <div className="section-background">
          <Image src={backgroundImage} />
        </div>
      )}
    </section>
  )
}

import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Carousel from '../../Carousel'
import {
  slugify,
  decodeEntities,
  useWindowDimensions,
} from '../../../utils/helpers'
import { Image } from '../../Image'
import './TestimonialSlider.scss'

/**
 * Reduce the amount of text from content.
 * Will not reduce if text is already shorter than char limit.
 * @param {String} str - Text to be trimmed.
 * @param {Number} limit - Total length cut up to.
 */
function truncateString(str = '', limit = 120) {
  let trimmedString = ''
  // remove all HTML tags from content string.
  trimmedString = str.replace(/(<([^>]+)>)/gi, '')
  return (
    trimmedString.substr(0, limit - 1) +
    (trimmedString.length > limit ? '...' : '')
  )
}

/**
 * Individual testimonial item
 * @param {Boolean} showLogo - Display logo
 * @param {Object} slide - Slide information
 * @param {String} className - Custom classname
 * @param {Number} slideTextLength - Used in truncating the string
 */
export const TestimonialItem = ({
  showLogo,
  slide,
  className,
  slideTextLength,
}) => {
  return (
    <div
      className={`${className}${
        showLogo ? ' logo-testimonial' : ' testimonial'
      }`}
    >
      <div className="wrap">
        <div className="inner">
          {showLogo && slide.acf.logo && (
            <div className="logo">
              <div className="inner-logo">
                <Image src={slide.acf.logo} />
              </div>
            </div>
          )}
          {slide && (
            <div className="content">
              <p>
                {decodeEntities(
                  truncateString(slide?.content, slideTextLength)
                )}
              </p>
              {slide.acf.name && (
                <div className="testimonial-name-and-title">
                  <span className="testimonial-name">
                    {decodeEntities(slide?.acf?.name)}
                  </span>
                  <br />
                  <span className="testimonial-title">
                    <strong>{decodeEntities(slide?.title)}</strong>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * Slider returns a wrapped Carousel with necessary items.
 * @param {Object[]} testimonials - The testimonial items.
 * @param {Boolean} showLogo - Toggles display of testimonial logo.
 * @param {String} title - Title to appear above slider.
 * @param {Boolean} listView - Change testimonial to display testimonials as a list instead of stacked.
 * @returns <Carousel />
 */
export const Slider = ({ testimonials, showLogo, title, listView }) => {
  const { width } = useWindowDimensions()

  const [truncateLimit, setTruncateLimit] = useState(120)

  useEffect(() => {
    if (width < 1200) {
      setTruncateLimit(320)
    } else if (width < 800) {
      setTruncateLimit(240)
    } else {
      setTruncateLimit(180)
    }
  }, [width])

  const settings = {
    container: `${slugify(title)}-slug`,
    nav: true,
    controls: false,
    mouseDrag: true,
    loop: false,
    responsive: {
      1080: {
        items: 3,
      },
      700: {
        items: 2,
      },
    },
  }

  return (
    <div className={!listView ? 'carousel-wrap' : 'list-wrap'}>
      {!listView && (
        <Carousel settings={settings}>
          {testimonials?.map(
            (slide, slideCount) =>
              slideCount < 8 && (
                <TestimonialItem
                  key={`testimonial-${slideCount}`}
                  slide={slide}
                  showLogo={showLogo}
                  className="slide"
                  slideTextLength={truncateLimit}
                />
              )
          )}
        </Carousel>
      )}
      {listView &&
        testimonials?.map((slide, slideCount) => (
          <TestimonialItem
            key={`testimonial-${slideCount}`}
            slide={slide}
            showLogo={showLogo}
            className="slide"
          />
        ))}
    </div>
  )
}

export const TestimonialPass = props => {
  const { title, showLogo, data, listView, highlight } = props
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials

  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <section className="testimonial-slider">
      {title && (
        <h2 className="section-title">
          <span className={highlight ? 'header-background' : ''}>
            {decodeEntities(title)}
          </span>
        </h2>
      )}
      <Slider
        testimonials={posts}
        showLogo={showLogo}
        title={title}
        listView={listView}
        highlight={highlight}
      />
    </section>
  )
}

export const TestimonialSlider = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              slug
              title
              acf {
                logo {
                  source_url
                }
                name
                position
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}
